.css-1ajpnr9-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent !important;
}

.belowOneMinute {
  animation: heartbeat 1s infinite;
  transform-origin: center;

  &.belowFifteenSeconds {
    animation: heartbeat 0.3s infinite;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
.image-contain {
  .css-3j6ntj,
  MuiBox-root.css-3j6ntj {
    object-fit: contain !important;
  }
}
.main-head-search {
  .css-1lblljk-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.no-hover-effect {
  &:hover {
    color: #1a447b !important;
    background-color: #d9deff !important;
  }
}
.my-pdf-reader {
  .my-pdf-reader-iframe {
    // display: none !important;
  }
}
.MuiFormLabel-asterisk.MuiInputLabel-asterisk,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-1ecnysf,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-4pefzv-MuiFormLabel-asterisk {
  color: red !important;
}
.hover-show-container {
  .hover-show {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .hover-show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.padding-0-container {
  padding: 0 10px !important;
}

.partner-register-container {
  // position: relative;
  padding: 0 25px;
  .partner-register {
    .register-view-container {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 996px) {
        top: 10%;
      }
    }
  }
  // .partner-register {
  //   margin-top: -30rem;
  //   width: 600px;
  //   margin-bottom: 100px;
  //   @media screen and (max-width: 1060px) {
  //     margin-top: -24rem;
  //   }
  //   @media screen and (max-width: 996px) {
  //     margin-top: -32rem;
  //   }
  //   @media screen and (max-width: 899px) {
  //     margin-top: -43rem;
  //   }
  //   @media screen and (max-width: 768px) {
  //     margin-top: -43rem;
  //     width: 100%;
  //   }

  //   @media screen and (max-width: 375px) {
  //     margin-top: -38rem;
  //   }
  // }
}

.small-select {
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    top: -5px;
  }
  .css-698ggw {
    height: 43px !important;
  }
  .css-em49yw .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .css-1cw0187 {
    height: 43px !important;
  }
  .css-3wnyxb .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    height: 43px !important;
  }
  .css-3wnyxb .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .MuiAutocomplete-hasPopupIcon.css-em49yw .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-em49yw .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .css-fq2zi1 {
    top: -5px;
  }
  .css-4odwut-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 43px;
  }
  .css-1p61z7h-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1qv85oo-MuiInputBase-root-MuiOutlinedInput-root {
    height: 43px;
  }
  .MuiFormControl-root {
    .css-ssonly-MuiInputBase-root-MuiOutlinedInput-root {
      height: 43px !important;
    }
  }
}
.chart-dropdown {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f5f5f5;
  }
}
.color-danger {
  color: red !important;
}
.role-select-card {
  // background-color: #fff !important;
  transition: all ease-in-out 0.3s;
  &.inactive {
    &:hover {
      border-color: #dbe0e4 !important;
      transform: scale(1.03);
    }
  }
}
.step-wrapper {
  position: relative;
  min-height: 500px;
  margin: 0 5px;
  .stepper-button {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .step {
    transition: all ease-in-out 0.6s;
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -33rem;
    margin-top: 2rem;
    &.active {
      left: 0;
    }
    &.inactive {
    }
  }
}
#responsive-dialog-title {
  overflow: hidden;
}
.partner-create-form {
  width: 500px;
}
.modal-input {
  .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4.5px 14px !important;
  }
}
.custom-accordion {
  .css-kdwozy-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
}

.css-12vka4q-MuiFormLabel-root-MuiInputLabel-root,
.css-1jqq78o-placeholder {
  font-size: 13px;
}

.placeholder-sm {
  &::placeholder {
    font-size: 12px; // Adjust the font size as needed
  }
}
textarea,
input {
  font-size: 14px !important;
}
.select2-selection__control {
  border-radius: 8px !important;
  border: 1px solid #e8ecee !important;
}
.custom-select-ui {
  label {
    top: -6px;
  }
  .custom-select-ui-child {
    height: 40px;
  }
  .css-1x5orh4-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}
// input::placeholder {
//   font-size: 12px !important;
// }

.box-shadow-unset {
  box-shadow: unset !important;
}
.partner-type-modal {
  .css-154lg22-MuiPaper-root-MuiDialog-paper {
    max-width: unset;
    min-width: 550px !important;
  }
}
.table-pd {
  .table-pd2 {
    .session-card-pd {
      width: 25px;
    }
  }
}
.session-table {
  td {
    vertical-align: top;
    padding: 8px;
  }
  tr {
    max-width: 10px;
  }
}

.cardPadding {
  .css-1j5s3cl {
    margin-bottom: 10px;
  }
  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}
.card2 {
  .css-8145p1 {
    margin-bottom: 10px;
  }
  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}
.card3 {
  .css-189e0nv {
    margin-bottom: 10px;
  }
  .css-1ludl2-MuiStack-root {
    margin-top: 15px;
  }
}
.single-view-taps {
  .single-view-tab {
    .css-heg063-MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
    }
    .css-6d3pg0-MuiTabs-indicator {
      display: none !important;
    }
  }
  #simple-tabpanel-0,
  #simple-tabpanel-1 {
    flex: 1;
  }
  .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border: 1px solid #009688;
    border-radius: 10px;
    color: #009688 !important;
  }
  .css-19kzrtu {
    padding: 0;
    padding-right: 4px !important;
  }
}
@media screen and (min-width: 1200px) {
  #sidebar-inActive {
    transition: all ease-in-out 0.3s;
    width: 65px;
    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 65px;
    }
    .css-121b4uz-MuiListItemIcon-root {
      min-width: 48px;
    }
    .css-1y6sui9 {
      width: 65px !important;
    }
  }

  #sidebar-active {
    transition: all ease-in-out 0.3s;
    width: 280px;
    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 280px;
    }
  }
}
@media screen and (max-width: 1200px) {
  #main-body-sidebar-active {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.my-new-select .css-13cymwt-control {
  border-radius: 6px;
  min-height: 40px !important;
}
.my-new-select .css-1jqq78o-placeholder {
  color: #212b36;
}
.session-update-form .css-1ifcsjq-MuiFormLabel-root-MuiInputLabel-root {
  z-index: -1 !important;
}

.my-new-select .select2-selection__menu.css-1nmdiq5-menu {
  z-index: 99;
  position: absolute;
}
.next-button.css-1umjv0b-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #fff !important;
}
.next-button.css-fkxtkf.Mui-disabled {
  color: #fff !important;
}
.div-chip {
  .div-delete {
    color: #cc3c1b !important;
    opacity: 1 !important;
    // &:hover{
    //   opacity: 1 !important;
    //   color: #CC3C1B !important;
    // }
  }
}

.react-player-video {
  iframe {
    border-radius: 12px;
    @media screen and (max-width: 2560px) {
      width: 1000px;
      height: 550px;
    }
    @media screen and (max-width: 1440px) {
      width: 720px;
      height: 450px;
    }
    @media screen and (max-width: 1024px) {
      width: 650px;
      height: 400px;
    }
    @media screen and (max-width: 768px) {
      width: 500px;
      height: 300px;
    }
  }
}
.course-search.css-1hgyc67-MuiToolbar-root {
  padding: 0px !important;
}
.custom-checkbox {
  .css-13p0ed3-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #933e88 !important;
  }
}
.custom-modal {
  .css-1s13gk4-MuiPaper-root-MuiDialog-paper {
    max-width: unset !important;
    width: 800px !important;
  }
  .css-1ft8eua {
    max-width: unset !important;
    width: 800px !important;
  }
}
.custom-phone-input {
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    border: none;
    //  border-left: 1px solid #efefef;
    //  border-radius: 0;
  }
  .css-v49clp {
    border: none;
  }
}
.custom-country-select {
  .css-gcgov3-MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    display: none;
  }
}
.custom-drawer {
  .css-1qsez4b {
    background-color: #25102e;
  }
}
